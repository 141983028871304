import * as React from 'react';
import SEO from '../components/Seo/Seo';
import Header from '../components/Header/Header';
import Contacts from '../components/Contacts/Contacts';
import Footer from '../components/Footer/Footer';
import ButtonMarkup from '../components/ButtonMarkup/ButtonMarkup';
import ScrollTopButton from '../components/ScrollTopButton/ScrollTopButton';
import ImageGallery from '../components/ImageGallery/ImageGallery';

import NewsSection from '../sections/News';
import ScheduleSection from '../sections/Schedule';
import PricesSection from '../sections/Prices';
import StaffSection from '../sections/Staff';
import CounterSection from '../sections/Counter';
import ClassesSection from '../sections/Classes';
import AdvantagesSection from '../sections/Advantages';

import ColorLogo from '../images/color-logo.png';
import GymVideoMp4 from '../videos/squat-it.mp4';
import mapIcon from '../images/pin.png';

const IndexPage: React.SFC<{}> = (props): JSX.Element => {
  return (
    <>
      <SEO
        keywords={[
          'кросфіт',
          'кросфіт Львів',
          'кроссфіт',
          'заняття з кросфіту',
          'crossfit',
          'crosfit',
          'кросфіт тренування',
          'зал для кросфіту',
          'Revat',
          'Реват',
        ]}
      />

      <section className="main-screen">
        <div className="container">
          <Header />

          <div className="main-screen-content">
            <img alt="Revat" src={ColorLogo} />
            <h1>
              Ставай кращим <br /> разом з нами!
            </h1>
            <a href="#contacts">
              <ButtonMarkup>
                Записатись на безкоштовне <br /> тренування
              </ButtonMarkup>
            </a>
            <br/>
            <div className="address-block">
              <h3 className="address-block-title">Наші локації:</h3>
              <address>
                <a href="https://maps.app.goo.gl/2HWWT2xW99jxmXtC7" target="_blank">
                  <span>
                    вул. Кульпарківська 93
                  </span>
                  <img src={mapIcon} alt="вул. Кульпарківська 93" />
                </a>
              </address>
              <address>
                <a href="https://goo.gl/maps/RU6y1cPijDB2" target="_blank">
                  <span>
                    вул. Жовківська 22
                  </span>
                  <img src={mapIcon} alt="вул. Жовківська 22" />
                </a>
              </address>
            </div>
          </div>
        </div>

        <div className="video-wrapper">
          <video
            className="video-inner"
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
          >
            <source src={GymVideoMp4} type="video/mp4" />
            {/* <source src={GymVideoWebm} type="video/webm" /> */}
            {/* <source
              src="http://thenewcode.com/assets/videos/polina.mp4"
              type="video/mp4"
            /> */}
          </video>
        </div>
      </section>

      <ClassesSection />
      <PricesSection />
      <ScheduleSection />
      <Contacts />
      <ImageGallery />
      <CounterSection />
      <StaffSection />
      <AdvantagesSection />
      <NewsSection />
      {/* <Map /> */}
      <Footer />
      <ScrollTopButton />
    </>
  )
}

export default IndexPage
